@import 'styles/utilities.scss';
.formContainer {
  position: relative;
  width: 100%;

  @include tablet {
    > div {
      display: flex;
      flex-direction: row;
    }
  }

  input {
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
    border: none;
    padding: var(--spacing-small);
    border-bottom: var(--ns-border-width) solid #000;
    line-height: 1;
    background: none;
    margin-right: var(--spacing-normal);

    &::placeholder {
      color: var(--color-on);
      opacity: 0.5;
    }
  }

  input:focus {
    outline: 0px solid #000;
    outline-offset: 0;
    border-radius: 0 !important;
    // border: none; /* Optional: Ensures no border conflicts */
    box-shadow: none !important; /* Prevents browser-specific focus styles */
  }
  button {
    font-size: var(--font-size-x-normal);
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    // margin-top: var(--spacing-x-normal);
    background: none;
    border: none;
    cursor: pointer;
    padding-left: 0;

    &:hover,
    &:focus {
      color: var(--color-on);
    }
  }
}
