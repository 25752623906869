// Headings
.header1 {
  font-size: var(--font-size-xxx-large);
  font-weight: var(--font-weight-extrabold);
  font-family: "neue-haas-grotesk-display", sans-serif;
}

.header2 {
  line-height: 1.6em;
  font-size: var(--font-size-x-large);
  font-weight: var(--font-weight-bold);
  margin: var(--spacing-x-small) 0 var(--spacing-x-small) 0;
}

.header3 {
  line-height: 1.6em;
  font-size: var(--font-size-x-large);
  font-weight: var(--font-weight-extrabold);
}

.header4 {
  line-height: 1.6em;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
}

.header5 {
  line-height: 1.6em;
  font-size: var(--font-size-normal);
  font-weight: var(--font-weight-bold);
}

.header1,
.header2,
.header3,
.header4,
.header5 {
  line-height: 1.6em;
}
