@import 'styles/utilities.scss';
.mailingList {
  margin-bottom: var(--spacing-x-large);
  background-color: var(--color-card-bg);
}

.mailformContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;

  @include tablet {
    // width: 50%;
    display: flex;
    justify-content: flex-start;
  }
}
