.pulsating-circle {
  background-color: #000;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  animation: pulse-animation 2s infinite;

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
}
